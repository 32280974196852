import React from 'react';
import {Box, Center, chakra, Image, VStack} from '@chakra-ui/react';
import {createRoot} from 'react-dom/client';
import Page from '../../components/Page';
import assetUrl from '../../utils/assetUrl';
import LoginForm from './components/LoginForm';
import ResetPasswordForm from './components/ResetPasswordForm';

// @ts-ignore
const {NEEDS_PASSWORD_RESET} = window;

const Auth = () => (
  <Page>
    <chakra.div position='fixed' left={0} top={0} right={0} bottom={0} bgColor='brand.800'>
      <Center>
        <VStack
          w={{base: 'full', sm: '400px', md: '600px'}}
          my='80px'
          alignItems='stretch'
          spacing={6}
        >
          <Box mx={10} p={8} bgColor='white' borderRadius='xl' border='solid 1px'>
            <Center bgGradient='linear(to-br, brand.500, pink.500 124%)' p={8} borderRadius='lg'>
              <Image src={assetUrl('/images/logo-white.svg')} alt='Calvient AutoPilot' w={200} />
            </Center>
            {NEEDS_PASSWORD_RESET ? <ResetPasswordForm /> : <LoginForm />}
          </Box>
        </VStack>
      </Center>
    </chakra.div>
  </Page>
);

const root = createRoot(document.getElementById('app') as HTMLElement);
root.render(<Auth />);
