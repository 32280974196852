import React, {FC, useState} from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
} from '@chakra-ui/react';
import api from '../../../api';

const ResetPasswordForm: FC = () => {
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmed, setNewPasswordConfirmed] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const redirectToHome = () => {
    window.location.href = '/';
  };

  const handleResetPassword = async () => {
    try {
      const response = await api.user.resetPassword(password, newPassword);
      if (response.success) {
        redirectToHome();
      } else {
        setErrorMessage(response.message ?? 'An unknown error occurred.');
      }
    } catch (e: any) {
      setErrorMessage(e.response.data.message);
    }
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Stack spacing={4}>
        <Alert status='info' borderRadius='md' mt={4}>
          <AlertIcon />
          <AlertDescription>Please create a new password.</AlertDescription>
        </Alert>

        {!!errorMessage && (
          <Alert status='error' borderRadius='md' mt={4}>
            <AlertIcon />
            <AlertDescription>{errorMessage}</AlertDescription>
          </Alert>
        )}

        {newPassword && newPasswordConfirmed && newPassword !== newPasswordConfirmed && (
          <Alert status='error' borderRadius='md' mt={4}>
            <AlertIcon />
            <AlertDescription>The passwords do not match.</AlertDescription>
          </Alert>
        )}

        <Box py={4}>
          <FormControl id='password' mt={4}>
            <FormLabel>Current Password</FormLabel>
            <Input type='password' onChange={(e) => setPassword(e.target.value)} />
          </FormControl>
          <FormControl id='password' mt={4}>
            <FormLabel>New Password</FormLabel>
            <Input type='password' onChange={(e) => setNewPassword(e.target.value)} />
          </FormControl>
          <FormControl id='password' mt={4}>
            <FormLabel>Confirm New Password</FormLabel>
            <Input type='password' onChange={(e) => setNewPasswordConfirmed(e.target.value)} />
          </FormControl>
        </Box>
        <Button
          w='full'
          type='submit'
          colorScheme='gray'
          disabled={
            !password ||
            !newPassword ||
            !newPasswordConfirmed ||
            newPassword !== newPasswordConfirmed
          }
          onClick={handleResetPassword}
        >
          Login
        </Button>
      </Stack>
    </form>
  );
};

export default ResetPasswordForm;
