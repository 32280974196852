import React, {FC, useState} from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
} from '@chakra-ui/react';
import api from '../../../api';
import TwoFactorAuth from './TwoFactorAuth';

const LoginForm: FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isAwaitingCode, setIsAwaitingCode] = useState(false);
  const [userId, setUserId] = useState(0);
  const [codeSentTo, setCodeSentTo] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const response = await api.login({email, password});
      // means we are in production and need 2FA
      if ('user' in response) {
        setUserId(response.user.id);
        setIsAwaitingCode(true);
        setCodeSentTo(response.codeSentTo);
      } else if (response.success) {
        window.location.href = '/';
      }
    } catch (e: any) {
      setErrorMessage(e.response.data.message);
    }
    setIsLoading(false);
  };

  if (isAwaitingCode && userId > 0) {
    return <TwoFactorAuth userId={userId} codeSentTo={codeSentTo} />;
  }

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Stack spacing={4}>
        {!!errorMessage && (
          <Alert status='error' borderRadius='md' mt={4}>
            <AlertIcon />
            <AlertDescription>{errorMessage}</AlertDescription>
          </Alert>
        )}

        <Box py={4}>
          <FormControl id='email'>
            <FormLabel>Email address</FormLabel>
            <Input type='email' onChange={(e) => setEmail(e.target.value)} name='email' />
          </FormControl>
          <FormControl id='password' mt={4}>
            <FormLabel>Password</FormLabel>
            <Input type='password' onChange={(e) => setPassword(e.target.value)} name='password' />
          </FormControl>
        </Box>
        <Button
          w='full'
          isLoading={isLoading}
          type='submit'
          colorScheme='brand'
          disabled={!email || !password}
          onClick={handleLogin}
        >
          Login
        </Button>
      </Stack>
    </form>
  );
};

export default LoginForm;
