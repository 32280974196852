import React, {FC, useState} from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
} from '@chakra-ui/react';
import api from '../../../api';

interface TwoFactorAuthProps {
  userId: number;
  codeSentTo: string;
}

const TwoFactorAuth: FC<TwoFactorAuthProps> = ({userId, codeSentTo}) => {
  const [code, setCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const redirectToHome = () => {
    window.location.href = '/';
  };

  const handleVerifyLogin = async () => {
    setIsLoading(true);
    try {
      const response = await api.verifyLogin(userId, {code});
      if (response.success) {
        redirectToHome();
      } else {
        setErrorMessage(response.message ?? 'An unknown error occurred.');
      }
    } catch (e: any) {
      setErrorMessage(e.response.data.message);
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Stack spacing={4}>
        <Alert status='info' borderRadius='md' mt={4}>
          <AlertIcon />
          <AlertDescription>
            Please enter the verification code sent to your {codeSentTo}.
          </AlertDescription>
        </Alert>

        {!!errorMessage && (
          <Alert status='error' borderRadius='md' mt={4}>
            <AlertIcon />
            <AlertDescription>{errorMessage}</AlertDescription>
          </Alert>
        )}

        <Box py={4}>
          <FormControl id='code' mt={4}>
            <FormLabel>Verification Code</FormLabel>
            <Input onChange={(e) => setCode(e.target.value)} />
          </FormControl>
        </Box>
        <Button
          w='full'
          isLoading={isLoading}
          type='submit'
          colorScheme='gray'
          disabled={!code}
          onClick={handleVerifyLogin}
        >
          Verify
        </Button>
      </Stack>
    </form>
  );
};

export default TwoFactorAuth;
